<template lang="pug">
.reserve-orderlist.flex.column
  .list.flex.column
    template(v-for="(item, index) in reserves")
      .list-item.flex.align-center.p-4.gap-20.clickable(
        :class="{'highlight': item.shouldLeave}"
        @click="show(item)")
        img(v-if="item.thumb" :src="item.thumb")
        img(v-else src='/user.png')
        .info.flex.justify-center.align-center.fill.gap-20
          .info.flex.column.align-start.gap-8.fill
            span 訂單編號 {{ item.orderNumber.toUpperCase() }}
            span 剩餘 {{ parseInt(item.remain / 60, 10) + 1 }} 分鐘
          p.currentStatus {{ item.statusText }}
      md-divider
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getReservedReserves } from '@/api/reserve';
import { getImageThumbPath } from '@/api/image';
import constant from '@/data/constant';

export default {
  name: 'ReserveOrderList',
  data() {
    return {
      reserves: [],
      statistics: {},
      now: parseInt(new Date().getTime() / 1000, 10),
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'headerTitle']),
  },
  methods: {
    ...mapMutations(['setHeaderTitle']),
    goPage(path) {
      this.showSideMenu = false;
      this.$router.push(path);
    },
    show(item) {
      console.log('show', item);
      this.goPage(`/order/${item.uuid}`);
    },
    async loadReserves(start, end) {
      const now = parseInt(new Date().getTime() / 1000, 10);
      const data = await getReservedReserves(start, end);
      const reserves = (data?.data?.data || []).map((r) => ({
        ...r,
        remain: r.end - r.actual_end,
        orderNumber: r.uuid.slice(0, 8),
        thumb: getImageThumbPath((r.notes || [])[0]?.photo_id),
        firstNote: (r.notes || [])[0]?.message || '',
        statusText: r.continued ? '已使用' : constant.orderStatusToText(r.status, r.end < this.now),
      }));
      reserves.sort((r1, r2) => {
        if (r1.end < now && r2.end > now) {
          return -1;
        }
        if (r1.end > now && r2.end < now) {
          return 1;
        }
        if (r1.status !== r2.status) {
          return r1.status - r2.status;
        }
        return r1.end - r2.end;
      });
      this.reserves = reserves;
      this.statistics = data?.data?.statistics || {};
    },
    async loadData() {
      const now = new Date();
      const end = new Date(now).setHours(23, 59, 59, 999);
      await this.$execWithLoading(async () => {
        const e = parseInt(end / 1000, 10);
        await this.loadReserves(0, e);
      });
    },
  },
  mounted() {
    this.setHeaderTitle('保留的訂單');
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/helper.scss";

.reserve-orderlist{
  overflow: hidden;
  .fab-fish, .fab-food{
    z-index: 5;
    bottom: 8px;
    right: 16px;
  }
  .fab-food{
    right: 88px;
    left: auto;
  }
  .status-header{
    flex: 0 0 auto;
  }
  .list{
    flex: 1;
    overflow: auto;
    overflow: overlay;
    padding-bottom: 120px;
    .list-item {
      &.highlight {
        background: lighten(#F42C04, 40%);
      }
      img {
        width: 120px;
        height: 120px;
        border-radius: 10%;
        padding: 4px;
      }
      .currentStatus {
        flex: 0 0 80px;
      }
    }
  }
}

</style>
